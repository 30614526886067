<template>
	<div class="order_wrap">
		<v-container class="">
			<div class="txt_box  px-6">
				<h1 class="tit_page">납부 (은행 즉시이체)</h1>
				<p class="txt_body1 mb-4 ">
					납부 금액은 총<span class="primary--text">
						{{ recpInfos.amount | comma }} 원</span
					>
					입니다.
					<br />
					고객님이 납부하실 계좌정보를 등록해 주세요.<br />
					<span class="txt_warn fln">※ 계약자 명의 계좌만 가능</span>
				</p>
			</div>

			<BankPayForm
				ref="bank"
				:hidePaymentDate="true"
				:hideCustType="true"
				:hideBizNo="true"
				:instantTransfer="true"
				:disabledCustName="disableCustType"
			/>
			<div class="bottom_box px-6">
				<v-btn color="primary" class="col_1_small" @click="goRecp()">
					{{ recpInfos.amount | comma }}원 납부 진행
				</v-btn>
				<v-btn
					depressed
					height="20"
					color=""
					class="btn_underline my-6"
					@click="onResetPayMethod"
				>
					납부방법 재선택
				</v-btn>
			</div>
			<CustomRecpDialog ref="recpPop" />
		</v-container>
	</div>
</template>
<script>
import filters from '@/mixins/filters'
import { bankInstantTransfer } from '@/apis/order.recp.api'
import {
	postWebOrderInfoById,
	fetchWebOrderInfoById
} from '@/apis/order.status.api'
export default {
	mixins: [filters],
	data() {
		return {
			recpInfos: {
				amount: '',
				kunnr: '',
				userId: '',
				phone: '',
				zwebRecp: '' //04:판매인 07:고객납부
			},
			mthd: '11', // 은행이체 - 기타결제
			disableCustType: false
		}
	},
	mounted() {
		const custType = this.$store.getters['verify/customerType']
		this.disableCustType = custType === 'P' ? true : false

		this.recpInfos = this.$store.getters['recp/recpInfos']
		if (!this.recpInfos.mthd) this.recpInfos.mthd = this.mthd
	},
	methods: {
		onResetPayMethod() {
			this.$router.go(-1)
		},
		async chkReturn(prm, rtn, tcObj) {
			this.$log('rtn:', JSON.stringify(rtn))
			if (rtn.TYPE === 'S') {
				// store 저장
				this.$store.dispatch('recp/setRecpParams', { ...prm })

				// 웹 주문정보 저장
				const mobOrderNo = this.$store.getters['verify/mobOrderNo']

				fetchWebOrderInfoById(mobOrderNo).then(res => {
					let webParams = res.resultObject
					let order = JSON.parse(webParams.orderInfoJson)
					order = Object.assign(order, {
						recp: {
							recpParams: this.$store.getters['recp/recpParams'],
							recpInfos: this.$store.getters['recp/recpInfos'],
							isCustSelfPay: true,
							recpBankResult: tcObj,
							payRecp: this.$store.getters['recp/payRecp']
						}
					})
					webParams.step = '06' // 전자계약
					webParams.statusCode = '108' // 서명입력
					webParams.orderInfoJson = JSON.stringify(order)

					postWebOrderInfoById(mobOrderNo, webParams).then(postRes => {
						this.$log('postRes: ', JSON.stringify(postRes))
						this.$alert({
							message:
								'일회성 납부금 이체는 계약서 서명 후 진행되니, 서명 전 잔액이 충분한지 확인해 주세요.<br/>"계속 진행하기"를 선택하여 전자 계약서 서명을 완료해 주세요.',
							okText: '계속 진행하기'
						}).then(x => {
							if (x) {
								this.$router.push({
									name: 'ec-cust-pass',
									query: { userKey: this.$route.query.userKey }
								})
							}
						})
					})
				})
			} else {
				this.$store.dispatch('recp/setIsCustSelfPay', true)
				this.$router.push({
					name: 'recp-cust-uncomplete',
					params: { status: '106', msg: rtn.MESSAGE }
				})
				// const options = {
				// 	title: '다음과 같은 사유로 납부가 거부됐습니다.',
				// 	guide: '납부방법을 재선택 바랍니다.',
				// 	contents: {
				// 		거부사유: rtn.MESSAGE
				// 	},
				// 	okText: '납부방법 재선택'
				////cancelText: ''
				// }
				// const popRes = await this.$refs.recpPop.open(options)
				// if (popRes) {
				// 	// 납부방법 재선택
				// 	this.onResetPayMethod()
				// }
			}
		},
		async goRecp() {
			const formData = this.$refs.bank.onSubmit()
			if (!formData) return
			this.$log('bank formData:', formData)

			let payRecp = {
				AF_OWNER_NM: formData.custName,
				AF_BANK_CD_NM: formData.bankNm,
				AF_CARD_NO: formData.accountNo
			}

			this.$store.dispatch('recp/setPayRecp', payRecp)

			const options = {
				title: '다음 계좌를 통해 즉시이체를 요청합니다.',
				guide: '즉시 이체는 계약서 서명 후 진행됩니다.',
				contents: {
					예금주: formData.custName,
					생년월일: formData.ssn,
					납부방법: `${formData.bankNm} 
					${this.$options.filters.star(formData.accountNo, 4)}`,
					납부금액: `${this.$options.filters.comma(this.recpInfos.amount)}원`
				},
				okText: '즉시이체',
				cancelText: '납부방법 재선택'
			}

			const popRes = await this.$refs.recpPop.open(options)

			if (popRes) {
				let prm = {
					bankc: formData.bankCd,
					suacc: formData.accountNo,
					resty: this.recpInfos.custType,
					resId: formData.ssn,
					depositor: formData.custName,
					okamt: this.recpInfos.amount,
					userId: this.recpInfos.userId,
					cellPhone: this.recpInfos.phone,
					kunnr: this.recpInfos.kunnr,
					mobOrderNo: this.$store.state['verify'].mobOrderNo,
					zwebRecp: '04'
				}

				// 은행즉시이체는 api 생략 - 원복
				const res = await bankInstantTransfer(prm)
				const rtn = res.resultObject.data.E_RETURN
				// const rtn = {
				// 	TYPE: 'S'
				// }
				await this.chkReturn(prm, rtn, res.resultObject.data.TC_BKACCT[0])
			} else {
				// 납부방법 재선택
				this.onResetPayMethod()
			}
		}
	}
}
</script>
